import React from "react"

import Layout from "../components/Layout"
import AboveTheFoldAboutUs from "../components/pages/au-coeur-de-greengo/AboveTheFoldAboutUs"
import Entrepreneurs from "../components/pages/au-coeur-de-greengo/Entrepreneurs"
import Join from "../components/pages/au-coeur-de-greengo/Join"
import OurStory from "../components/pages/au-coeur-de-greengo/OurStory"
import Team from "../components/pages/au-coeur-de-greengo/Team"
import SEO from "../components/Seo"

const AuCoeurDeGreengo = () => (
  <Layout activePage="notre-equipe">
    <SEO
      title="Notre équipe"
      description="La Consigne GreenGo est une app' mobile permettant d’emprunter facilement des emballages réutilisables et consignés dans tout type de structures : restaurants, cafétérias d’entreprises et grande distribution."
      keywords={["équipe", "candidature", "offre", "consigne", "zéro déchets"]}
    />
      <AboveTheFoldAboutUs />
      <OurStory />
      <Team />
  </Layout>
)

export default AuCoeurDeGreengo
