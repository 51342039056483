import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"

import "./OurStory.scss"

const OurStory: React.FunctionComponent = () => {
  const images = useStaticQuery(graphql`
    query {
      team: file(relativePath: { eq: "au-coeur-de-greengo/consigne-greengo-equipe-sept-2021.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, srcSetBreakpoints: [500, 1000]) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      founders: file(relativePath: { eq: "au-coeur-de-greengo/founders.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, srcSetBreakpoints: [500, 1000]) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <section className="our-story section-padding">
      <div className="container">
        <h2 className="heading heading--section-title heading--svg-underline">
          Notre histoire
        </h2>
        <div className="our-story__content-wrapper">
          <div>
            <div className="image-with-text">
              <Img
                {...images.founders.childImageSharp}
                alt="L'équipe de GreenGo"
                className="image-with-text__image"
              />
              <div className="image-with-text__text">
                <p className="paragraph">
                  <strong>
                    La Consigne GreenGo, c’est l’aventure de deux entrepreneurs
                    passionnés, réunis par l’ambition de donner à chacun la
                    possibilité d’agir sur sa planète.
                  </strong>
                </p>
                <br />
                <p className="paragraph">
                  Diplômée de Centrale Supelec en 2012, Yasmine débute sa vie
                  professionnelle pendant 4 ans en tant qu’ingénieur pétrolier.
                  Elle passe ensuite 2,5 ans chez McKinsey. Le déclic écologique
                  qu’elle connaît et son envie d’entreprendre lui fait quitter
                  ce monde des grandes entreprises.
                </p>
                <br />
                <p className="paragraph">
                  Au même moment, Lucas Graffan, diplômé de l’EM Lyon, quitte le
                  grand groupe Procter & Gamble où il occupait le poste
                  d’analyste financier depuis 4 ans. Après avoir passé 8 mois en
                  Asie à la rencontre de 50 fablabs, il rentre en France et
                  décide de se dédier à la problématiquede réduction des
                  déchets.
                </p>
              </div>
            </div>
            <div className="image-with-text image-with-text--inverted">
              <Img
                {...images.team.childImageSharp}
                alt="L'équipe de GreenGo"
                className="image-with-text__image"
              />
              <div className="image-with-text__text">
                <p className="paragraph">
                  En mai 2018, par hasard, au détour d’une conférence ils se
                  font introduire par une amie en commun qui avait senti le
                  potentiel d’une telle rencontre entre Lucas et Yasmine. Et
                  elle avait vu juste ! Depuis ce jour, ils ne se sont plus
                  quittés et ont travaillé dur pour conclure leur association en
                  octobre 2018 lors de la signature de leur premier client
                  biocoop où ils ont installé leur première solution de consigne
                  digitale GreenGo en magasin.
                </p>
                <br/>
                <p className="paragraph">
                  Yasmine rappelle très justement que{" "}
                  <strong>
                    « le meilleur déchet est celui que l’on ne crée pas ».
                  </strong>
                </p>
                <p className="paragraph">
                  Le système de la consigne fonctionnait très bien il y a
                  plusieurs décennies, pourquoi ne pas la remettre au goût du
                  jour en la modernisant ? Ils décident de s’attaquer en
                  priorité à la restauration rapide, un mode de consommation qui
                  génère énormément de déchets tous les jours, et qui fait de
                  plus en plus d’adeptes chaque année.
                </p>
              </div>
            </div>
          </div>
          <a
            className="link button button--color-pink"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.welcometothejungle.com/fr/companies/la-consigne-greengo/jobs"
          >
            Rejoindre l'équipe
          </a>
        </div>
      </div>
    </section>
  )
}

export default OurStory
