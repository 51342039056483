import { graphql, useStaticQuery } from "gatsby"
import Img, { GatsbyImageProps } from "gatsby-image"
import * as React from "react"

import LinkedInIcon from "../../../images/shared/linkedin-brands.svg"

import "./Team.scss"

const Team: React.FunctionComponent = () => {
  const data: {
    mdx: {
      frontmatter: {
        members: {
          firstName: string
          linkedin: string | null
          image: { childImageSharp: GatsbyImageProps }
          role: string
        }[]
      }
    }
  } = useStaticQuery(graphql`
    fragment ProfilePic on File {
      childImageSharp {
        fixed(width: 300) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    query TeamMembers {
      mdx(frontmatter: {templateKey: {eq: "TeamMember"}}) {
        frontmatter {
          members {
            firstName
            linkedin
            image {
              ...ProfilePic
            }
            role
          }
        }
      }
    }
  `)
  const members = data.mdx.frontmatter.members;
  return (
    <section className="team section-padding" id="team">
      <div className="container">
        <h2 className="heading heading--section-title heading--svg-underline">
          Une équipe de passionnés
        </h2>
        <ul className="team__content-wrapper">
          {members.map((teamMember, index) => (
            <li
              className="team-member"
              key={`${teamMember.firstName}-${index}`}
            >
              <Img
                {...teamMember.image.childImageSharp}
                className="team-member__image"
                alt={`Photo de ${teamMember.firstName}`}
              />
              <div className="team-member__name-and-profile">
                <div className="team-member__placeholder hide-handheld"></div>
                <h3>{teamMember.firstName}</h3>
                {teamMember.linkedin && <a
                  href={teamMember.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={LinkedInIcon} alt="Page Linkedin" height="25" />
                </a>}
              </div>
              <p>{teamMember.role}</p>
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}

export default Team
