import React from "react"
import "./AboveTheFoldAboutUs.scss"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const AboveTheFoldAboutUs: React.FunctionComponent = () => {
  const images = useStaticQuery(graphql`
    query {
      aboutusbg: file(name: { eq: "aboutus-header-bg" }) {
        childImageSharp {
          fluid(
            maxWidth: 2880
            quality: 100
            srcSetBreakpoints: [800, 1275, 1440, 1920, 2880]
          ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <section className="above-the-fold-about-us section-padding">
      <div className="above-the-fold-about-us__background-image">
        <Img
          fluid={images.aboutusbg.childImageSharp.fluid}
          style={{ height: "100%" }}
          loading="eager"
          fadeIn={false}
        />
      </div>
      <div className="above-the-fold-about-us__app1 container">
        <div className="above-the-fold-about-us__text">
          <h1 className="heading heading--1">Notre équipe</h1>
          <p className="paragraph paragraph--white paragraph--header">
            Depuis 2018, notre équipe travaille passionnément pour faire de La
            Consigne GreenGo le 1er réseau de partenaires sans emballage jetable
          </p>
          <a href="#team" className="link button button--color-pink">
            Découvrir notre équipe
          </a>
        </div>
      </div>
    </section>
  )
}

export default AboveTheFoldAboutUs
